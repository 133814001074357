<template>
    <div class="btn">
        <input type="text" v-model="name" placeholder="输入文件名">
        <button @click="getGame">生成文件</button>
        <div>生成数据表</div>
        <input type="text" v-model="dataName" placeholder="输入表明名">
        <button @click="addData">添加</button>
        <button @click="upLoad">上传</button>
        <div v-for="item in dataList" :key="item">
            {{item}}
        </div>
        <div>{{dataList.length}}个</div>
    </div>
</template>

<script>
import gameList from '@/utils/data'
import { getRandomNumbers } from "@/utils/api"
import { upLoadData } from "@/api/games"
export default {
    data(){
        return {
            gameList:[],
            allKeyList:[],
            name:'',
            dataName:'',
            dataList:[]
        }
    },
    mounted() {
        let keyList = []
        gameList.games.forEach(item=>{
            keyList.push(item.key)
        })
        this.allKeyList = Array.from(new Set(keyList));
    },
    methods:{
        getGame(){
            this.gameList = [];
            let keyList = []
            let randomNum = getRandomNumbers(0,this.allKeyList.length-1,13);
            randomNum.forEach(item=>{
                keyList.push(this.allKeyList[item])
            })
            this.gameList = gameList.games.filter(item=> keyList.indexOf(item.key) != -1);

            const content = JSON.stringify({games:this.gameList},null,2);

            // 创建一个Blob实例，类型为JavaScript
            const blob = new Blob([content], { type: 'application/json' });

            // 创建一个指向该Blob的URL
            const url = URL.createObjectURL(blob);

            // 创建一个a标签并设置属性
            const a = document.createElement('a');
            a.href = url;
            a.download = this.name+'.js'; // 设定下载文件名

            // 添加a标签到文档并模拟点击
            document.body.appendChild(a);
            a.click();

            // 清理并移除元素和对应的URL对象
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        },
        addData(){
            if(this.dataList.indexOf(this.dataName) !== -1){
                alert('修改数据表名后再添加')
            }else{
                this.dataList.push(this.dataName)
            }
        },
        upLoad(){
            this.dataList.forEach(item=>{
                upLoadData({name:item}).then(res=>{
                    console.log(res)
                })
            })
        }
    }
}
</script>

<style lang="less" scoped>
.btn{
    display: block;
    margin: 100px;
}
</style>